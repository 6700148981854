import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeroThankYou from 'src/components/Hero/HeroThankYou';

const ThankYouPage = () => {
    const { fileName } = useStaticQuery(
        graphql`
            query ThankYouQuery {
              fileName: file(relativePath: {eq: "ThankYou.jpg"}) {
                id
                absolutePath
                childImageSharp{
                  fluid(maxWidth: 750, quality: 60){
                    aspectRatio
                    base64
                    originalImg
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
              }
            }
        `
    );
    const image = fileName.childImageSharp.fluid;
    return (
        <>
            <HeroThankYou image={image} />
        </>
    );
};
export default ThankYouPage;